import React from 'react'
import '../css/About.css'
import date1 from '../Json_date/date.json'
import NotFoundPage from '../components/NotFoundPage'
// import { motion as m } from "framer-motion/dist/framer-motion"
import { AnimatePresence, motion as m } from "framer-motion"
import { container, item } from "../styleComponents/StyleAnimation"


function About() {


    return (

        <>
            {/* show Loading */}
            {/* 
            <div className='loadingCountPersonalClinic'>
                <img src="https://img.pikbest.com/png-images/20190918/cartoon-snail-loading-loading-gif-animation_2734139.png!f305cw" />
            </div> */}

            <m.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                // initial={{ y: "100%" }}
                // animate={{ y: "0%" }}
                exit={{ opacity: 0 }}
                transition={{ duration: 2, ease: "easeOut" }}
                style={{ paddingBottom: "1.8%" }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
            >
                <m.div variants={container} initial="hidden" animate="show"
                    className="titleAbout" class="card text-center bg-light border-secondary  mb-3">
                    <h1 className="titleAbout" class="card-header">NUESTRO CREDO</h1>
                    <div class="card-body">
                        <p class="card-text">
                            <m.p variants={item}> Hemos depositado nuestra confianza y nuestros sueños en Dios y Guatemala.
                             Creemos que la mayor fortaleza de nuestro país es su gente y que en Grupo TrePilastri constituye el valor agregado 
                             que hace que cada proyecto sea un éxito. </m.p>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                        </p>
                    </div>
                </m.div>
                <m.div variants={container} initial="hidden" animate="show"
                    className="titleAbout" class="card text-center bg-light border-secondary  mb-3">
                    <h1 className="titleAbout" class="card-header">NUESTROS VALORES</h1>
                    <div class="card-body">
                        <p  class="card-text">
                            <m.p variants={item}> TRANSPARENCIA</m.p>
                            <m.p variants={item}> PUNTUALIDAD</m.p>
                            <m.p variants={item}> HONESTIDAD</m.p>
                            <m.p variants={item}> INTEGRIDAD</m.p>
                            <br />
                            <br />
                        </p>
                    </div>
                </m.div>
                <m.div variants={container} initial="hidden" animate="show"
                    className="titleAbout" class="card text-center bg-light border-secondary  mb-3">
                    <h1 className="titleAbout" class="card-header"> NUESTRA MISIÓN</h1>
                    <div class="card-body">
                        <p  class="card-text">
                            <m.p variants={item}> Ser un proveedor de servicios de calidad en</m.p>
                            <br />
                            <m.p variants={item}> DESARROLLOS</m.p>
                            <m.p variants={item}> CONSTRUCCIÓN</m.p>
                            <m.p variants={item}> PLANIFICACIÓN</m.p>
                            <m.p variants={item}> MATERIALES</m.p>
                            <m.p variants={item}> FABRICACIÓN</m.p>
                            <m.p variants={item}> a nivel nacional e internacional alcanzando los más altos estándares de calidad 
                            y con ello satisfacer y superar las expectativas de nuestros clientes.</m.p>
                            <br />
                            <br />
                            <br />
                        </p>
                    </div>
                </m.div>
                <m.div variants={container} initial="hidden" animate="show"
                    className="titleAbout" class="card text-center bg-light border-secondary  mb-3">
                    <h1 className="titleAbout" class="card-header"> NUESTRA VISIÓN</h1>
                    <div class="card-body">
                        <p  class="card-text">
                            <m.p variants={item}> Ser una empresa</m.p>
                            <m.p variants={item}> SÓLIDA</m.p>
                            <m.p variants={item}> CONFIABLE</m.p>
                            <m.p variants={item}> LEAL</m.p>
                            <m.p variants={item}> Ser reconocida por el talento HUMANO, los VALORES y el PROFESIONALISMO 
                            construyendo relaciones duraderas entre clientes y grupo de interés.</m.p>
                            <br />
                            <br />
                            <br />
                            <br />
                        </p>
                    </div>
                </m.div>

                <br />

                {/* <div className='cardsInfoAbout' >

                    <div className="a-box">
                        <div className="img">
                            <div className="img-inner">
                                <div className="inner-skew">
                                    <img src="https://scontent.fgua1-3.fna.fbcdn.net/v/t39.30808-6/346283814_660303815935440_7353195264339127634_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=3635dc&_nc_ohc=K4avfCboCdoAX9_yyxP&_nc_ht=scontent.fgua1-3.fna&oh=00_AfC8WMBEyri367-rHt6VpgwQtLU8qk-aDoob9HJS5iIgPQ&oe=65EBC921" alt="icon" />
                                </div>
                            </div>
                        </div>

                        <div className="textDark">
                            <h3>Equipo de trabajo</h3>
                            <div>

                            </div>
                        </div>
                    </div>

                    <div className="a-box">
                        <div className="img">
                            <div className="img-inner">
                                <div className="inner-skew">
                                    <img src="https://scontent.fgua1-2.fna.fbcdn.net/v/t39.30808-6/349347525_2237653516422192_1895182591085976372_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=3635dc&_nc_ohc=GtlezGDoOkUAX9C442V&_nc_ht=scontent.fgua1-2.fna&oh=00_AfAEmd5lLIOZJ_kMuHojhQzWdTU5rkYtIhbUOXBeggs5Eg&oe=65EA90C4" alt="icon" />
                                </div>
                            </div>
                        </div>

                        <div className="textDark">
                            <h3>Nuestros clientes</h3>
                            <div>

                            </div>
                        </div>
                    </div>


                    <div className="a-box" >
                        <div className="img">
                            <div className="img-inner">
                                <div className="inner-skew">
                                    <img src="https://i.postimg.cc/rm47nPQc/reviewuser.png" alt="icon" />
                                </div>
                            </div>
                        </div>

                        <div className="textDark">
                            <h3>Comentarios de nuestros clientes</h3>
                            <div>

                            </div>
                        </div>
                    </div>

                </div> */}
            </m.div >
        </>
    )

}

export default About;
import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import '../css/Contactenos.css'
import date1 from '../Json_date/date.json'
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import { Modal } from 'react-bootstrap';
import SendEmail from '../components/SendEmail'
// import { motion as m } from "framer-motion/dist/framer-motion"
import { AnimatePresence, motion as m} from "framer-motion"
import { container, item } from "../styleComponents/StyleAnimation"



//here we show Contactenos the clinic , send message + show data from json file
function Contactenos() {

    // show popup send message
    const [showSendMessage, setShowSendMessage] = useState(false);


    const popUpSendMessage = () => {
        setShowSendMessage(true);
    }



    const closePopUpModelSendMessage = () => {
        setShowSendMessage(false);
    }



    return (

        <m.div variants={container} initial="hidden" animate="show">
            <section className="bannerContactenos">

                <div className="boxContactenos">

                    <h3>Contáctenos</h3>

                    <m.p variants={item}><span className="boldFirstWordDark">
                        Ciudad :</span> {date1.Map.country} , {date1.Map.City}
                    </m.p>
                    <m.p variants={item}><span className="boldFirstWordDark">
                        Dirección :</span> {date1.Map.addressCity} , {date1.Map.adressNum}
                    </m.p>
                    <m.p variants={item}><span className="boldFirstWordDark">
                        Correo :</span> info@grupotrepilastri.com
                    </m.p>
                    <m.p variants={item}><span className="boldFirstWordDark">
                        Teléfonos :</span> +502 2219 - 9690  / +502 5199 - 3416 
                    </m.p>

                    <br />

                    {/* <div className='UserSendEmail'>
                        <Button style={(storedTheme === "light") ? { background: "green" } : (storedTheme === "dark") ? { background: "contained" } : ""}
                            variant="contained"
                            onClick={popUpSendMessage}
                            startIcon={<EmailIcon />}>
                            Click Enviar Mensaje
                        </Button>
                    </div>

                    <>
                        <Modal show={showSendMessage} onHide={closePopUpModelSendMessage} >
                            <SendEmail hideModelSendMessage={closePopUpModelSendMessage} />
                        </Modal>
                    </> */}

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1288.4362954690482!2d-90.52112723233009!3d14.602120949249146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a31a67480bcf%3A0x28540649f1f05e44!2sGrupo%20Tre%20Pilastri!5e0!3m2!1ses!2sgt!4v1711409658230!5m2!1ses!2sgt"
                        width="100%" height="280" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
                
                <div className="box contectDark" >

                    <div className="log">
                        <h3>Horario laboral</h3>

                        {date1.hours_work.map((record) => (

                            <m.div variants={item} key={record.id}>
                                <p>{record.day} : {record.time}</p>
                            </m.div>
                        ))}
                        <br />
                        <br />
                        <Card.Img variant="top" src="https://i.postimg.cc/508kqF22/1zx.png" />
                        <br />
                        <br />
                    </div>

                </div>

            </section>
        </m.div>

    )

}

export default Contactenos;
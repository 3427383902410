// here use animation text + load page use ( framer-motion )

export const container = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            delayChildren: 0.5,
            staggerChildren: 0.2,
        },
    },
};



export const item = {
    hidden: { y: "100%", opacity: 0 },
    show: {
        opacity: 1, transition: { duration: 0.5 }
    }
};
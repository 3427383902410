import { BrowserRouter, Route, Switch } from "react-router-dom";
import React from 'react'
import Home from './Pages/Home'
import Menu from './components/menu'
import Fotter from './components/fotter'
import Contactenos from './Pages/Contactenos'
import Servicios from './Pages/Servicios'
import About from './Pages/About'
// import DarkMode from "./components/DarkMode"
import NotFoundPage from "./components/NotFoundPage";


function App() {

  return (

    <BrowserRouter>

      {/* Dark mode or light
      <DarkMode /> */}

      <Menu />

      <Switch>
        <Route path="/" exact component={Home} />      
        <Route path="/About" exact component={About} />
        <Route path="/Contactenos" exact component={Contactenos} />
        <Route path="/Servicios" exact component={Servicios} />
        {/* active page not found */}
        <Route path="*" exact component={NotFoundPage} />
      </Switch>

      <Fotter />

    </BrowserRouter>
  );
}

export default App;
import 'bootstrap/dist/css/bootstrap.css'
import { Nav, Navbar, Container, Button, Modal } from 'react-bootstrap'
import React, { useState } from 'react'
import '../css/menu.css'
import "../css/login.css"
import { useHistory, Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import Cookies from 'js-cookie';


function Menu() {


    // //let storedTheme = localStorage.getItem("theme");
    // let storedTheme = Cookies.get("theme")!=null?Cookies.get("theme"):null;
    // //let userData = JSON.parse(sessionStorage.getItem("user"));
    // let userData = JSON.parse(Cookies.get("user")!=null?Cookies.get("user"):null);



    // const defaultDark = storedTheme === "dark" || (storedTheme === null);

    // if (defaultDark) {
    //     //localStorage.setItem("theme", "dark");
    //     Cookies.set("theme", "dark");
    //     document.documentElement.setAttribute("data-theme", "dark");
    // }


    return (
        <>
            <div className="menu">
                <Navbar collapseOnSelect expand="sm" >
                    <Container>

                        <Link to='/'>
                            {/* <Navbar.Brand ><img src="https://scontent.fgua5-1.fna.fbcdn.net/v/t39.30808-6/273592216_100323655919771_2519043351063984830_n.png?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=fncHPw0zPE8AX9vF2_J&_nc_ht=scontent.fgua5-1.fna&oh=00_AfAeZFFRoMdnMUHUzn_dWx2D3B5Q_j26b4y20Y3V8nT3-w&oe=646552AE" alt="icon" /></Navbar.Brand> */}
                        </Link>

                        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ border: "1px solid gray", background: "#424242" }} />

                        <Navbar.Collapse id="responsive-navbar-nav">

                             <Nav className="ms-auto">
                                <Nav.Link  style={{ color: "#FFFFFF" }}
                                    as={Link} to="/"><img className="logo" src="/logoT.png" alt="" /></Nav.Link>
                                <Nav.Link  style={{ textAlign: "Center", color: "#FFFFFF", fontWeight: "777" }}
                                    as={Link} to="/About">QUIÉNES SOMOS</Nav.Link>
                                <Nav.Link  style={{ color: "#FFFFFF", fontWeight: "777" }}
                                    as={Link} to="/Servicios">SERVICIOS</Nav.Link>                                    
                                 <Nav.Link  style={{ color: "#FFFFFF", fontWeight: "777" }}
                                    as={Link} to="/About">PROYECTOS</Nav.Link>
                                <Nav.Link  style={{ color: "#FFFFFF", fontWeight: "777" }}
                                    as={Link} to="/Ferreteria">FERRETERÍA</Nav.Link>
                                <Nav.Link  style={{ color: "#FFFFFF", fontWeight: "777" }}
                                    as={Link} to="/Muebles">MUEBLES</Nav.Link>                                         
                                <Nav.Link  style={{ color: "#FFFFFF", fontWeight: "777" }}
                                    as={Link} to="/Contactenos">CONTÁCTENOS</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    );
}


export default Menu;
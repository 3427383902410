import React from 'react'
import { Button } from 'react-bootstrap'
import '../css/NotFoundPage.css'


export default function NotFoundPage() {

    return (

        <div className='notFoundPage'>

            <p className="zoom-area"><b>Oh no!!</b>
                <br />No estas escribiendo bien la URL o solicitando una página que ya no existe.
                <br />
                o la información de la base de datos no carga
            </p>

            <section className="error-show">
                <span className="four"><span className="screen-reader-text">4</span></span>
                <span className="zero"><span className="screen-reader-text">0</span></span>
                <span className="four"><span className="screen-reader-text">4</span></span>
            </section>

            <div className="link-show">
                <Button className="more-link" variant="success" size="sm" href="/" >
                   Regresar a la página de inicio
                </Button>
            </div>

        </div>
    )
}
import { Button, Modal, Form } from 'react-bootstrap'
import '../css/home.css'
import React, { useState } from 'react'
// import videoBg from '../images/FilmForth Untitled.mp4'
import Swal from 'sweetalert2'
import CloseIcon from '@mui/icons-material/Close';
// import { motion as m } from "framer-motion/dist/framer-motion"
import { AnimatePresence, motion as m} from "framer-motion"
import { container, item } from "../styleComponents/StyleAnimation"

import Cookies from 'js-cookie';

// Home page
function Home() {

  
    return (

        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            // initial={{ y: "100%" }}
            // animate={{ y: "0%" }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
        >

            <div className='main1'>
                {/* <video src={videoBg} loop autoPlay muted playsInline /> */}
                {/* <link src='https://i.postimg.cc/KzGWXd0Z/5901203-2009.jpg' /> */}
                <img src="/portada-trepilastri.jpg" alt="" width="500" height="1000"/>
                {/* <m.div variants={container} initial="hidden" animate="show" className="content">
                    <m.h1 variants={item}>Bienvenidos</m.h1>
                    <m.p variants={item}>Grupo TREPILASTRI</m.p>
                    <br />
                </m.div> */}
            </div>





            {/* show all Category from json file
            < Category /> */}

        </m.div >
    )

}

export default Home;
import React from 'react'
import { Tabs, Tab, Button, Row, Form, Col, Table } from 'react-bootstrap';
import '../css/Servicios.css'
// import { motion as m } from "framer-motion/dist/framer-motion"
import { motion as m } from "framer-motion"



//show data from json file our work this clinic
function Servicios() {

    return (
        <>
            <m.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                // initial={{ y:"100%" }}
                // animate={{ y:"0%" }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.75, ease: "easeOut" }}
            >
                <div className='styleServiciossModel'>

                    <div className="titleServicios">
                        <br />
                        <h1>Servicios que realizamos</h1>
                    </div>
                    <Tabs id="controlled-tab-example" className="mb-3 tabsChiose " >

                        <Tab eventKey="show all table" title="" className='AllTable'>
                            <Table responsive bordered hover size="sm" variant={""}>
                                <thead>
                                    <tr>
                                        <th style={{ width: "1%", textAlign: "center" }}></th>
                                        <th style={{ width: "1%", textAlign: "center" }}></th>
                                        <th style={{ width: "1%", textAlign: "center" }}></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr>
                                        <td style={{ width: "33%", textAlign: "justify", fontSize: "15px" }}>
                                            <Table responsive bordered hover size="sm" variant={""}>
                                                <tbody>
                                                    <tr>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col" colspan="2">
                                                            <img src="/servicios1.PNG" alt="" width="1000" height="450" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                                            Sketch de propuesta
                                                            arquitectónica a nivel
                                                            de conjunto para complejo de spa y centro de
                                                            retiro.
                                                            Propuesta ubicada en
                                                            Atitlán Guatemala,
                                                            desarrollada a nivel de
                                                            ante proyecto.
                                                        </td>
                                                        <td style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                                            <ul className='ServiciosLook' class="list-group">
                                                                <li className='ServiciosLook' class="list-group-item">Diseños residenciales</li>
                                                                <li className='ServiciosLook' class="list-group-item">Diseños urbanos residenciales</li>
                                                                <li className='ServiciosLook' class="list-group-item">Proyectos comerciales</li>
                                                                <li className='ServiciosLook' class="list-group-item">Proyectos industriales</li>
                                                                <li className='ServiciosLook' class="list-group-item">TIPOS DE PROYECTOS</li>
                                                            </ul>


                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                        <td style={{ fontFamily: "Polly Bold", width: "45%", textAlign: "justify", fontSize: "15px" }}><h1>Diseño arquitectónico</h1>
                                            <p>Nuestro objetivo es que nuestros clientes
                                                queden 100% satisfechos con sus propuestas
                                                arquitectónicas.
                                                Elaboramos todo el proceso de acompañamiento con el cliente, desde el programa de
                                                necesidades, visitas de campo y sitio, estudio
                                                de propuestas, hasta llegar a nuestra solución:
                                                el objeto arquitectónico, realizando siempre,
                                                todas las propuestas de apoyo (modelos y maquetas) para presentación del proyecto.
                                                Hemos podido desarrollar variedad de propuestas arquitectóncias, las cuales, ¡muchas de
                                                ellas están en pie y en funcionamiento!
                                                01
                                            </p>
                                        </td>
                                        <td style={{ fontFamily: "Polly Bold", width: "20%", textAlign: "justify", fontSize: "15px" }}
                                        ><p>Se presentan algunos
                                            diseños propuestos para proyectos no residenciales, los
                                            cuales, son los de mayor
                                            dimensión en nuestro portafolio,
                                                <br />
                                                <br />
                                                Adicional, contamos con
                                                amplia experiencia y diseños vendidos y entregados
                                                de tipo residencial.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                            <Table responsive bordered hover size="sm" variant={""}>
                                                <tbody>
                                                    <tr>
                                                        <td scope="col" colspan="4">
                                                            <img src="/servicios2.PNG" alt="" width="1000" height="450" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Polly Bold", width: "100%", textAlign: "justify", fontSize: "15px" }}>
                                                            PROPUESTA PARA EL NUEVO
                                                            HOSPITAL REGIONAL DE MAZATENANGO, SUCHITEPÉQUEZ, GUATEMALA.
                                                            <br />
                                                            <br />
                                                            La propuesta fue presentada ante las
                                                            entidades gubernativas correspondientes, quedando a disposición de
                                                            ellos el proyecto.
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                        <td scope="col" colspan="2" style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                            <Table responsive bordered hover size="sm" variant={""}>
                                                <tbody>
                                                    <tr>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                                            <p>La propuesta de diseño abarcó cada fase incluyendo la intervención urbana correspondiente para el
                                                                ingreso al complejo.
                                                                Se cubrieron cada una de las áreas a desarrollar,
                                                                desde las primordiales, hasta las áreas de apoyo y
                                                                servicio.
                                                                <br />
                                                                <br />
                                                                Siendo un hospital regional, es uno de los proyectos de mayor magnitud protectado de nuestra
                                                                parte, y, adicional, se elaboró el master plan correspondiente con las fases a construir, respecto a periodos legislativos.
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col" colspan="2">
                                                            <img src="/servicios3.PNG" alt="" width="1000" height="400" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "Right", fontSize: "15px" }}>
                                            <Table responsive bordered hover size="sm" variant={""}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "Right", fontSize: "15px" }}>
                                                            EDIFICIO DE APARTAMENTOS,
                                                            ZONA 11, CIUDAD DE GUATEMALA.
                                                            <br />
                                                            <br />
                                                            Se desarrolló una propuesta para
                                                            apartamentos tipo B, con uso mixto
                                                            en niveles inferiores.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col" colspan="4">
                                                            <img src="/servicios4.PNG" alt="" width="1000" height="450" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                        <td scope="col" colspan="2" style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                            <Table responsive bordered hover size="sm" variant={""}>
                                                <tbody>
                                                    <tr>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col" colspan="2">
                                                            <img src="/servicios5.PNG" alt="" width="1000" height="400" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Polly Bold", width: "100%", textAlign: "justify", fontSize: "15px" }}>
                                                            <p>
                                                                Uno de los retos de desarrollo del proyecto, es el
                                                                hecho de la zona a la que pertenece y la distribución del terreno, siendo irregular (en forma de L) y
                                                                no regular como generalmente se plantea.
                                                                <br />
                                                                <br />
                                                                El proyecto fue entregado a totalidad en la fase de
                                                                planificación, con todas las fases correspondientes,
                                                                incluyendo, todos los aspectos normativos para
                                                                cumplimiento de los mismos, entiéndase: Municipalidad de Guatemala, MARN, MSPAS, Aeronaútica
                                                                civil, y FHA.
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%", textAlign: "justify", fontSize: "15px" }}>
                                            <Table responsive bordered hover size="sm" variant={""}>
                                                <tbody>
                                                    <tr>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col" colspan="2">
                                                            <img src="/servicios2_1.PNG" alt="" width="1000" height="450" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                                            Propuesta de proyecto
                                                            residencial elaborado
                                                            con metodología
                                                            MEP-INSTALACIONES.
                                                            Proyecto ubicado en la
                                                            zona 12, de la Ciudad de
                                                            Guatemala.
                                                        </td>
                                                        <td style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                                            <ul className='ServiciosLook' class="list-group">
                                                                <li className='ServiciosLook' class="list-group-item">Fase movimientos de tierra.</li>
                                                                <li className='ServiciosLook' class="list-group-item">Fase arquitectura.</li>
                                                                <li className='ServiciosLook' class="list-group-item">Fase MEP instalaciones</li>
                                                                <li className='ServiciosLook' class="list-group-item">Fase estructuras</li>
                                                                <li className='ServiciosLook' class="list-group-item">TIPOS DE PROYECTOS</li>
                                                            </ul>


                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                        <td style={{ width: "45%", textAlign: "justify", fontSize: "15px" }}><h1>Modelados metodología BIM</h1>
                                            <p>Desarrollamos los proyectos con metodologí
                                                BIM según la fase que sea requerida.
                                                Para desarrollos de proyectos, generalment
                                                incluimos todas las fases a cabadilidad, par
                                                trabajar en coordinación y evitar percances a
                                                momento de la ejecución del proyecto.
                                                Incluímos las tablas de cuantificación correspondientes a sus fases, facilitando temas d
                                                elaboración de presupuestos, mejorando interpretación de planos por visualizaciones en 3D
                                                Podemos adaptar planos 2D existentes, a
                                                modelados 3D, y aplicación de BIM
                                            </p></td>
                                        <td style={{ width: "20%", textAlign: "justify", fontSize: "15px" }}
                                        ><p>Se presentan algunos contenidos desarrollados con la
                                            metodología BIM, claro está,
                                            dicha información se presenta de manera organizada mediante planos, organigramas y cuadros como
                                            corresponde.
                                                <br />
                                                <br />
                                                También, se entregan archivos editables o según
                                                corresponda con el tipo de
                                                archivo.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td scope="col" colspan="3">
                                            <Table responsive bordered hover size="sm" variant={""}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "50%", textAlign: "Center", fontSize: "15px" }}>
                                                            <img src="/servicios2_2.PNG" alt="" width="1000" height="400" />
                                                        </td>
                                                        <td style={{ width: "50%", textAlign: "Center", fontSize: "15px" }}>
                                                            <img src="/servicios2_3.PNG" alt="" width="1000" height="400" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col" colspan="2" style={{ width: "100%", textAlign: "Center", fontSize: "15px" }}>
                                                            <p>Ejemplificación de trabajo realizado con modelado en Revit, creando superficies topográficas existentes y modificadas, para
                                                                ayuda en el proceso de diseño, ejemplificación y presentación hacia el cliente, elaboración de presupuesto de movimiento de tierras. </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td scope="col" colspan="3">
                                            <Table responsive bordered hover size="sm" variant={""}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "50%", textAlign: "Center", fontSize: "15px" }}>
                                                            <img src="/servicios2_4.PNG" alt="" width="1000" height="400" />
                                                        </td>
                                                        <td style={{ width: "50%", textAlign: "Center", fontSize: "15px" }}>
                                                            <img src="/servicios2_5.PNG" alt="" width="1000" height="400" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col" colspan="2" style={{ width: "100%", textAlign: "Center", fontSize: "15px" }}>
                                                            <p>Ejemplificación de trabajos realizado con modelado en Revit, creación de circuitos hidrosanitarios, y creación de modelado de
                                                                estructuras, en el producto entregado, se incluyen tablas de planificación, como demás información pertinente de planificación. </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%", textAlign: "justify", fontSize: "15px" }}>
                                            <Table responsive bordered hover size="sm" variant={""}>
                                                <tbody>
                                                    <tr>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col" colspan="2">
                                                            <img src="/servicios3_1.PNG" alt="" width="1000" height="450" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                                            Planos entregados para
                                                            diseño y ejecución de
                                                            planta de tratamiento,
                                                            para la Ciudad de Guatemala.
                                                        </td>
                                                        <td style={{ fontFamily: "Polly Bold", width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                                            <ul className='ServiciosLook' class="list-group">
                                                                <li className='ServiciosLook' class="list-group-item">Planos arquitectónicos.</li>
                                                                <li className='ServiciosLook' class="list-group-item">Planos estructurales.</li>
                                                                <li className='ServiciosLook' class="list-group-item">Planos de instalaciones</li>
                                                                <li className='ServiciosLook' class="list-group-item">Detalles en general</li>
                                                                <li className='ServiciosLook' class="list-group-item">TIPOS DE PROYECTOS</li>
                                                            </ul>


                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                        <td style={{ width: "45%", textAlign: "justify", fontSize: "15px" }}><h1>Planos BI dimensionales</h1>
                                            <p>En el caso de que nuestro servicio sea requerido unicamente como apoyo de dibujo bi
                                                dimensional. Generalmente, para construcciones y trámites de construicción y/o compra
                                                venta.
                                                Para el desarrollo de la planificación en 2D únicamente se requiere memorias y referencias
                                                para poder desarrollar cualquier juego de
                                                plano solicitado.
                                            </p></td>
                                        <td style={{ width: "20%", textAlign: "justify", fontSize: "15px" }}
                                        ><p>La elaboración de planos
                                            puede ser entregada autenticada (por profesional
                                            competente) o por medio
                                            de copias simples.
                                                <br />
                                                <br />
                                                Contamos con amplia
                                                experiencia de “maquilado” de planos.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%", textAlign: "justify", fontSize: "15px" }}>
                                            <Table responsive bordered hover size="sm" variant={""}>
                                                <tbody>
                                                    <tr>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col" colspan="2">
                                                            <img src="/servicios4_1.PNG" alt="" width="1000" height="450" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                                            Desarrollo de imagenes
                                                            foto realistas para proyecto residencial, ubicado en San Pedro
                                                            Sacatepéquez, Guatemala.
                                                        </td>
                                                        <td style={{ width: "50%", textAlign: "justify", fontSize: "15px" }}>
                                                            <ul className='ServiciosLook' class="list-group">
                                                                <li className='ServiciosLook' class="list-group-item">Renders foto realistas.</li>
                                                                <li className='ServiciosLook' class="list-group-item">Presentaciones tipo sketch.</li>
                                                                <li className='ServiciosLook' class="list-group-item">Videos de recorrido de proyectos.</li>
                                                                <li className='ServiciosLook' class="list-group-item">Elaboración de presentación tipo editorial.</li>
                                                                <li className='ServiciosLook' class="list-group-item">TIPOS DE PROYECTOS</li>
                                                            </ul>


                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                        <td style={{ width: "45%", textAlign: "justify", fontSize: "15px" }}><h1>Visualización Renderización</h1>
                                            <p>Este servicio generalmente es incluido en cualquier servicio de planificación y diseño contemporáneo, dado que, es muy importante para los clientes, poder tener una imagen clara e idea de su proyecto previo a la iniciación de la construcción, o el
                                                cierre de cualquier negocio.
                                                Nosotros ofrecemos el servicio de renderización
                                                de una manera natural, en el que las expectativas
                                                de nuestros clientes puedan ser cumplidas y satisfechas, mejorando incluso, la obra construida, sin
                                                que las imagenes sean “inalcanzables”.
                                            </p></td>
                                        <td style={{ width: "20%", textAlign: "justify", fontSize: "15px" }}
                                        ><p>Se muestran algunas de
                                            nuestras imágenes foto
                                            realistas producidas para
                                            presentación a clientes y
                                            lograr la venta de los distintos proyectos.s
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td scope="col" colspan="3">
                                            <Table responsive bordered hover size="sm" variant={""}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "10%", textAlign: "Center", fontSize: "15px" }}>
                                                            <img src="/servicios4_2.PNG" alt="" width="1400" height="600" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col" colspan="2" style={{ width: "100%", textAlign: "Center", fontSize: "15px" }}>
                                                            <p>
                                                                Ejemplificación de renders, o imagenes foto realistas de proyectos desarrolladas.

                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>

                                </tbody >

                            </Table >
                        </Tab >
                    </Tabs >
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div >
            </m.div >
        </>

    )

}

export default Servicios;
import { MDBFooter, MDBIcon } from 'mdb-react-ui-kit';
import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../css/fotter.css'

import Cookies from 'js-cookie';

function Fotter() {


    //let storedTheme = localStorage.getItem("theme");
    // let storedTheme = Cookies.get("theme")!=null?Cookies.get("theme"):null;

    return (

        <>
            <div className='Fotr'>

                <MDBFooter className={"text-center text-white fotrIcon"}>

                    <div className='container p-6 pb-0'>
                        <br />
                        <section className='mb-4'>
                            <a
                                className='btn btn-primary btn-floating m-1'
                                style={{ backgroundColor: '#3b5998', border: "none" }}
                                href='https://www.facebook.com/people/Grupo-Tre-Pilastri/61554806775553/?mibextid=qi2Omg'
                                role='button'
                            >
                                <MDBIcon fab icon='facebook-f' />
                            </a>

                            <a
                                className='btn btn-primary btn-floating m-1'
                                style={{ backgroundColor: '#25D366', border: "none" }}
                                href='https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D50251993416%26text%3DEnlace%253A%250Ahttps%253A%252F%252Ffb.me%252F4gbEKxaOB%250A%250AVi%2520esto%2520en%2520Facebook...%26app%3Dfacebook%26fbclid%3DIwAR0qktkMGYSdov1RH72ZX3vRuQj8Cg-aO6XRgrfGfZRBKdgspWBwdtI07Gg&h=AT09vNnOksP8NXfs0RjCbr-pRulKnJE-TKmoIYQLIoL5BVPi4RNdsZmDt85boU4iMUiMCdwERnPjr2Y2Skj92VAVPApd95dGEaItdBlfnQ0TnZvy7jybynDLmdCGo2_eKgopvA&__tn__=-UK-R&c[0]=AT2I19-YQm1fYH22nqqk0ryiJFCV_MVlEunGVhYWNEYLiL8TreS0uGAaRn4runFbRNmVINRxcscjrJNqLZo7UQHF9ejIggTi8JyVXOcqtGi6S7yNKhcOGhyTpGVotbhNNmY0isyBKE3iZvzILmEDmX3jO9eLwP2IytbMBQAK5YXrgJwZgtoCKeAY5gX3f8YPVYcA4mIPho7b'
                                role='button'
                            >
                                <MDBIcon fab icon='whatsapp' />
                            </a>
                            <br />
                        </section>
                    </div>

                    <div className={"text-center p-3 end"} >
                        <br />
                        <br />
                        <br />
                        <p>© Copyright   Corporation CSE, S.A.</p>
                    </div>
                </MDBFooter>
            </div>
        </>
    )

}

export default Fotter;